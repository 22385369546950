import React from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import SeoTags from "./SeoTags";

const PrivacyPolicy = () => {
     const { t } = useTranslation();

     // TAGS SEO
     const title = t('aboutustitle')
     const description = t('seoaboutustagdesc')

     return (
          <Fragment>
               <SeoTags
                    title={title}
                    description={description}
                    url="https://www.superbuxx.com/aboutus"
               />
               <div className="container-fluid banner-header text-white text-center py-3" style={{ minHeight: "24rem" }}>
                    <h1>{t('aboutustitle')}</h1>
               </div>

               <div className="container">
                    <div className="row">
                         <div className="col-12">
                              <p>{t('aboutus_p01')}</p>
                              <h2>{t('aboutus_subtitle01')}</h2>
                              <p>{t('aboutus_p02')}</p>
                              <h2>{t('aboutus_subtitle02')}</h2>
                              <ul>
                                   <li>{t('aboutus_p03')}</li>
                                   <li>{t('aboutus_p04')}</li>
                                   <li>{t('aboutus_p05')}</li>
                              </ul>

                              <h2>{t('aboutus_subtitle03')}</h2>
                              <ul>
                                   <li>{t('aboutus_p06')}</li>
                                   <li>{t('aboutus_p07')}</li>
                                   <li>{t('aboutus_p08')}</li>
                              </ul>

                              <h2>{t('aboutus_subtitle04')}</h2>
                              <p>{t('aboutus_p09')}</p>
                         </div>
                    </div>
               </div>
          </Fragment>
     )
}

export default PrivacyPolicy