import { AuthContext } from "../../contexts/AuthContext";
import React, { useContext, useEffect, useState, Fragment } from "react";
import { getUserData } from "../../components/functions/GetUserData";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SeoTags from "../common/SeoTags";

const ExchangeBuxx = props => {
     const { t } = useTranslation()
     const { idPais } = useContext(AuthContext)
     const { usuario } = useContext(AuthContext)
     const { isCellVerify } = useContext(AuthContext)
     const { isMailVerify } = useContext(AuthContext)
     const { uid, email } = usuario
     const [showModal, setShowModal] = useState(false)
     const [idMoeda, setIdMoeda] = useState(null)
     const [saldo, setSaldo] = useState(false)
     const [saldoRestante, setSaldoRestante] = useState(0)
     const [totalExchange, setTotalExchange] = useState(0)
     const [valorAReceber, setValorAReceber] = useState(0)
     const [comoReceber, setComoReceber] = useState(0)
     const [statusBotaoMais, setStatusBotaoMais] = useState(false)
     const [statusBotaoMenos, setStatusBotaoMenos] = useState(false)
     const [statusSelect, setStatusSelect] = useState(true)
     const [statusBtOk, setStatusBtOk] = useState(true)
     const [showAviso, setShowAviso] = useState(true)
     const [showAvisoCpf, setShowAvisoCpf] = useState(false)
     const [showAvisoPix, setShowAvisoPix] = useState(false)
     const [showAvisoDados, setShowAvisoDados] = useState(false)
     const [showBadRequest, setShowBadReqest] = useState(false)
     const [fiatPay, setFiatPay] = useState(null)
     const [showModalMail, setShowModalMail] = useState(false)

     // Const by COUNTRIES
     // [BR]:
     const [brPix, setBrPix] = useState(null)
     const [brCpf, setBrCpf] = useState(null)
     const [showPayData, setShowPayData] = useState(false)
     const [noCpf, setNoCpf] = useState(false)
     const [noPix, setNoPix] = useState(false)
     // [GLOBAL]:
     const [paypalKey, setPayPalKey] = useState(null)
     const [showPayPalData, setShowPayPalData] = useState(false)
     const [noPPal, setNoPPal] = useState(false)

     const navigate = useNavigate();

     // Restaura dados do User
     useEffect(() => {
          getUserData(uid).then((data) => {
               setSaldo(data.bux_balance)
               setSaldoRestante(data.bux_balance)
               if (data.bux_balance >= 5) {
                    setStatusBotaoMais(false)
               }
          });
     }, []);

     // Restaura dados de pagamento, se houver
     useEffect(() => {
          fetch("https://www.superbuxx.com:3030/get-user-pay-data-br/" + uid, {
               method: "GET",
               headers: { "Content-Type": "application/json" }
          })
               .then(response => response.json())
               .then(data => {
                    setBrCpf(data.br_cpf)
                    setBrPix(data.br_pix)
                    setPayPalKey(data.global_paypal)
               })
               .catch(error => {
                    console.error("Erro na requisição:", error);
               });
     }, [uid])

     //Calcula valor a receber
     useEffect(() => {
          if (idPais == 1) {
               // Calcula em Reais
               const reais = totalExchange / 2
               const total = reais.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
               setValorAReceber(total)
               setSaldoRestante(saldo - totalExchange)
               setFiatPay(reais)
          } else {
               // Calcula em Dólares - se o país do user não for Brasil
               const usd = totalExchange / 10
               const total = usd.toLocaleString('en', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 });
               setValorAReceber(total)
               setSaldoRestante(saldo - totalExchange)
               setFiatPay(usd)
          }

          if (totalExchange == 0) {
               setStatusBotaoMenos(true)
          } else {
               setStatusBotaoMenos(false)
          }

          if (saldo - totalExchange > 5) {
               setStatusBotaoMais(false)
          } else {
               setStatusBotaoMais(true)
          }

          if (totalExchange >= 50) {
               setStatusSelect(false)
               setShowAviso(false)
          } else {
               setStatusSelect(true)
               setShowAviso(true)
               setComoReceber(0)
               setShowPayData(false)
               setShowPayPalData(false)
          }
     }, [totalExchange, comoReceber])

     useEffect(() => {
          if (comoReceber == 2 || comoReceber == 3) {
               // Escolheu PAYPAL
               if (!paypalKey) {
                    setStatusBtOk(true)
               } else {
                    setStatusBtOk(false)
               }
          }
          if (comoReceber == 0) {
               setStatusBtOk(true)
          }
     }, [comoReceber])

     useEffect(() => {
          // Se o país for Brasil, id moeda = 8 (BRL), se não id moeda = 1 (USD)
          if (idPais == 1) {
               setIdMoeda(8)
          } else {
               setIdMoeda(1)
          }
     }, [idPais]);

     async function handleOrder(uid, total_buxx, payment_method_id, id_coin, total_fiat_paid) {
          try {
               if (isCellVerify == null || isCellVerify == false) {
                    setShowModal(true)
                    return
               }
               if (isMailVerify == null || isMailVerify == false) {
                    setShowModalMail(true)
                    return
               }

               const response = await fetch("https://www.superbuxx.com:3030/exchange-create-order", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ uid, total_buxx, payment_method_id, id_coin, total_fiat_paid }),
               });

               if (response.ok) {
                    navigate("/userexchangehistory")
                    // Rotina assíncrona que envia email ao usuário informando o status do pedido de troca (com gravação de log em caso de erro)
               } else {
                    setShowBadReqest(true)
               }
          } catch (error) {
               console.error(error)
               // Grava dados em log
          }
     }

     const handleSelect = (e) => {
          const selectedValue = parseInt(e.target.value, 10);
          setComoReceber(selectedValue);

          // Verifica se foram informados dados de pagamento
          if (selectedValue == 1) {
               setShowPayData(true)
               setShowPayPalData(false)
               if (!brPix) {
                    setNoPix(true)
               } else {
                    setNoPix(false)
               }

               if (!brCpf) {
                    setNoCpf(true)
               } else {
                    setNoCpf(false)
               }

               if (!brPix || !brCpf) {
                    setStatusBtOk(true)
                    setShowAvisoDados(true)
               } else {
                    setStatusBtOk(false)
               }
          } else if (selectedValue == 2) {
               setShowPayPalData(true)
               setShowPayData(false)
               if (!paypalKey) {
                    setStatusBtOk(true)
                    setNoPPal(true)
               } else {
                    setStatusBtOk(false)
                    setNoPPal(false)
               }
          } else {
               setShowPayData(false)
          }
     }

     const handlePlus = () => {
          setTotalExchange(totalExchange + 5)
     }

     const handleMinus = () => {
          setTotalExchange(totalExchange - 5)
     }

     const handleClose = () => {
          if (showModal) {
               setShowModal(false);
          }
     };

     const handleCloseAvisoDados = () => {
          if (showAvisoDados) {
               setShowAvisoDados(false);
          }
     };

     const handleCloseBadReq = () => {
          if (showBadRequest) {
               setShowBadReqest(false)
          }
     }

     const handleCloseModalMail = () => {
          if (showModalMail) {
               setShowModalMail(false)
          }
     }

     return (
          <Fragment>
               <SeoTags url="https://www.superbuxx.com/exchangebuxx" />
               <div className="container-fluid banner-header text-white text-center py-3">
                    <h1>{t('redeemtitleh1')}</h1>
               </div>
               <div className="container-fluid container-md">
                    <div>
                         <p className="title-swap">{t('howmuchbuxx')}</p>
                    </div>
                    <div className="counter-container">
                         <button id="decrement" className="counter-button" onClick={handleMinus} disabled={statusBotaoMenos}>-</button>
                         <span id="counter" className="counter-value">5</span>
                         <button id="increment" className="counter-button" onClick={handlePlus} disabled={statusBotaoMais}>+</button>
                         <div className="input-field first-input-swap">
                              <input type="text" className="form-control" id="formControlInput1" placeholder={`${totalExchange} Buxx`} disabled="true" />
                         </div>
                    </div>

                    <div className="row">
                         <div className="col-md-6">
                              <div className="custom-block">
                                   <h2>{valorAReceber}</h2>
                                   <p>{t('uwillean')}</p>
                              </div>
                         </div>
                         <div className="col-md-6">
                              <div className="custom-block">
                                   <h2>{saldoRestante} Buxx</h2>
                                   <p>{t('yourbalancewillbe')}</p>
                              </div>
                         </div>
                    </div>

                    <div className="form-group input-select-card">
                         <div className="col-12">
                              <select
                                   className="form-select custom-select"
                                   value={comoReceber}
                                   onChange={(e) => { setComoReceber(e.target.value); handleSelect(e) }}
                                   disabled={statusSelect}
                                   id="tipopgto"
                              >
                                   <option value="0" selected >{t('choosekindofpay')}</option>
                                   {idPais == "1" ? (
                                        <>
                                             <option value="1" >Pix</option>
                                             <option value="2" >PayPal</option>
                                        </>
                                   ) : (
                                        <>
                                             <option value="2" >PayPal</option>
                                             <option value="3" >Amazon Gift Card</option>
                                        </>
                                   )}
                              </select>
                         </div>
                    </div>

                    {
                         showPayData && (
                              <div className="row">
                                   <div className="col-6 mb-3">
                                        <div className="input-field">
                                             <label htmlFor="pixkey" className="form-label">{t('pixkeylabel')}</label>
                                             <input
                                                  type="text"
                                                  className={`form-control ${showAvisoPix}`}
                                                  id="pixkey"
                                                  name="pix"
                                                  placeholder={t('pixkeylabel')}
                                                  onChange={(e) => setBrPix(e.target.value)}
                                                  value={brPix}
                                                  disabled='true'
                                             />
                                        </div>
                                        {noPix ? (
                                             <div className="sbx-warning-area">
                                                  <small className="sbx-msg-bad">{t('nopixkey')}</small>
                                             </div>
                                        ) : (null)}
                                   </div>
                                   <div className="col-6 mb-3">
                                        <div className="input-field">
                                             <label htmlFor="docnum" className="form-label">{t('pixcpf')}</label>
                                             <input
                                                  type="text"
                                                  className={`form-control ${showAvisoCpf}`}
                                                  id="docnum"
                                                  name="doc"
                                                  placeholder="CPF"
                                                  onChange={(e) => setBrCpf(e.target.value)}
                                                  value={brCpf}
                                                  disabled='true'
                                             />
                                        </div>
                                        {noCpf ? (
                                             <div className="sbx-warning-area">
                                                  <small className="sbx-msg-bad">{t('nocpf')}</small>
                                             </div>
                                        ) : (null)}
                                   </div>
                              </div>
                         )
                    }

                    {
                         noCpf || noPix ? (
                              <a href="brpayinfo"><button className="btn btn-to-check-2 col-12 mb-3">{t('typepaydata')}</button></a>
                         ) : (null)
                    }

                    {
                         showPayPalData ? (
                              <div className="row">
                                   <div className="col-6 mb-3">
                                        <div className="input-field">
                                             <label htmlFor="ppalkey" className="form-label">{t('paypalkeylabel')}</label>
                                             <input
                                                  type="text"
                                                  className={`form-control ${showAvisoCpf}`}
                                                  id="ppalkey"
                                                  name="ppalkey"
                                                  placeholder={t('paypalkeylabel')}
                                                  value={paypalKey}
                                                  disabled='true'
                                             />
                                        </div>
                                        {noPPal ? (
                                             <div className="sbx-warning-area">
                                                  <small className="sbx-msg-bad">{t('nopaypalkey')}</small>
                                             </div>
                                        ) : (null)}
                                   </div>
                              </div>

                         ) : (null)
                    }

                    <button
                         className="btn btn-to-check-2 col-12"
                         disabled={statusBtOk}
                         onClick={() => handleOrder(uid, totalExchange, comoReceber, idMoeda, fiatPay)}
                    >
                         {t('confirmredeembtn')}
                    </button>
                    {showAviso ? (
                         <div className="sbx-warning-area mt-2">
                              <small className="sbx-msg-bad">► {t('minimredeem')} 50 BUXX</small>
                         </div>
                    ) : (null)}
               </div>
               <div className="container-fluid container-md">
                    <div className="col-12 banner-footer">
                         <img src="../src/assets/images/banner-footer.png" alt="" className="img-fluid" />
                    </div>
               </div>

               {
                    showModal || noCpf || noPix || showBadRequest || showModalMail ? (
                         <div className="toast-container" style={{ position: "fixed" }}>

                              {showModal && (
                                   <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
                                        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={handleClose}></button>
                                        <div className="toast-body">
                                             <div className="icon">
                                                  <img src="../src/assets/icons/toast-warning.svg" alt="" />
                                             </div>
                                             <strong>{t('nessaction')}</strong>
                                             <p>{t('verphonetxt1')}</p>
                                             <p>{t('verphonetxt2')}</p>
                                             <a href="/verifycell" style={{ textDecoration: "none" }}><button type='button' className="buxx-btn">{t('verifycellbt')}</button></a>
                                        </div>
                                   </div>
                              )}

                              {showAvisoDados && (
                                   <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
                                        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={handleCloseAvisoDados}></button>
                                        <div className="toast-body">
                                             <div className="icon">
                                                  <img src="../src/assets/icons/toast-warning.svg" alt="" />
                                             </div>
                                             <strong>{t('nessaction')}</strong>
                                             <p>{t('verpixtxt')}</p>
                                             <a href="/brpayinfo" style={{ textDecoration: "none" }}><button type='button' className="buxx-btn">{t('putdatabt')}</button></a>
                                        </div>
                                   </div>
                              )}

                              {showBadRequest && (
                                   <div className="toast" role="alert" aria-live="assertive" aria-atomic="true">
                                        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={handleCloseBadReq}></button>
                                        <div className="toast-body">
                                             <div className="icon">
                                                  <img src="../src/assets/icons/toast-error.svg" alt="" />
                                             </div>
                                             <strong>{t('transxnfail')}</strong>
                                             <p>{t('transxnfailtxt')}</p>
                                        </div>
                                   </div>
                              )}

                              {showModalMail && (
                                   <div class="toast-container">
                                        <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
                                             <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={handleCloseModalMail}></button>
                                             <div className="toast-body">
                                                  <div className="icon">
                                                       <img src="../src/assets/icons/toast-warning.svg" alt="" />
                                                  </div>
                                                  <strong>{t('nessaction')}</strong>
                                                  <p>{t('nomailvery')}.</p>
                                                  <p>{t('needverifymailtxt')}</p>
                                                  <a href="/verifyemail" style={{ textDecoration: "none" }}><button type='button' className="buxx-btn">{t('verifymailbt')}</button></a>
                                             </div>
                                        </div>
                                   </div>
                              )}

                         </div>
                    ) : (null)
               }

          </Fragment>
     )
}

export default ExchangeBuxx