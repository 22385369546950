import React from "react";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { addNewAdEvent } from "../functions/AddNewAdEvent";
import { getUserGeoData } from "../../helpers/users";
import { sendEmailVerification } from "firebase/auth";
import { useTranslation } from "react-i18next";

const SignUpMail = () => {
     const { t } = useTranslation()
     const [verifySenha, setVerifysenha] = useState("");
     const [confClass, setConfClass] = useState("");
     const [avisoSenha, setAvisoSenha] = useState("");
     const [confSenha, setConfSenha] = useState("");
     const [email, setEmail] = useState("");
     const [senha, setSenha] = useState("");
     const [senhaClass, setSenhaclass] = useState("");
     const [mailClass, setMailClass] = useState("");
     const [signupres, setSignupres] = useState("");
     const [mailres, setMailres] = useState("");
     const { setUsuario } = useContext(AuthContext);
     const auth = getAuth();
     const navigate = useNavigate();

     const [min8Color, setMin8Color] = useState("gray")
     const [maiusculaColor, setMaiusculaColor] = useState("gray")
     const [minusculaColor, setMinusculaColor] = useState("gray")
     const [specialColor, setSpecialColor] = useState("gray")
     const [numberColor, setNumberColor] = useState("gray")
     const [stateBtCriar, setStateBtCriar] = useState(true)
     const [isAgreeCheck, setIsAgreeCheck] = useState(false)
     const [showAviso, setShowAviso] = useState(false)
     const [idCountry, setIdCountry] = useState(null)
     const [nameCountry, setNameCountry] = useState(null)
     const [idPais, setIdPais] = useState(null)

     // Resgata track click id do localstorage (ADS TRACKING)
     const adsTrackClickId = localStorage.getItem('sbxclickid')

     let id_Paes = null;

     // Coleta os dados geográficos o user
     async function fetchUserGeoData() {
          try {
               const userGeoData = await getUserGeoData();
               const nomePais = userGeoData.countryName;
               const codPais = userGeoData.countryCode;
               setIdCountry(codPais)
               setNameCountry(nomePais)
          } catch (error) {
               console.log(error)
          }
     }
     fetchUserGeoData();

     async function getPaisData(code, name) {
          try {
               const response = await fetch("https://www.superbuxx.com:3030/get-pais/" + code + "/" + name)
               const data = await response.json()
               const id_pais = data.cData.id_pais
               setIdPais(id_pais)

          } catch (error) {
               console.log(error)
          }
     }
     getPaisData(idCountry, nameCountry);


     useEffect(() => {
          if (confSenha !== senha) {
               setConfClass("entrada-invalida")
               setShowAviso(true)
          } else {
               setConfClass("")
               setShowAviso(false)
          }
     }, [confSenha])

     useEffect(()=>{
          if(idPais == 1) {
               auth.languageCode = 'pt'
          } else {
               auth.languageCode = 'en'
          }
     }, [idPais])

     useEffect(() => {
          // 8 caracteres
          const min8Strings = /^.{8,}$/;
          if (min8Strings.test(senha)) {
               setMin8Color("green")
          } else {
               setMin8Color("gray")
          }

          // 1 maiúscula
          const min1Maiuscula = /^(?=.*[A-Z]).*$/;
          if (min1Maiuscula.test(senha)) {
               setMaiusculaColor("green")
          } else {
               setMaiusculaColor("gray")
          }

          // 1 minúscula
          const min1Minuscula = /^(?=.*[a-z]).*$/;
          if (min1Minuscula.test(senha)) {
               setMinusculaColor("green")
          } else {
               setMinusculaColor("gray")
          }

          // 1 número
          const min1Number = /^(?=.*\d).*$/;
          if (min1Number.test(senha)) {
               setNumberColor("green")
          } else {
               setNumberColor("gray")
          }

          // 1 caractere especial
          const min1Special = /^(?=.*[@$!%*#?&]).*$/;
          if (min1Special.test(senha)) {
               setSpecialColor("green")
          } else {
               setSpecialColor("gray")
          }

     }, [senha])

     const handleAgree = (event) => {
          if (event.target.checked) {
               setStateBtCriar(false)
          } else {
               setStateBtCriar(true)
          }
          setIsAgreeCheck((current) => !current);
     };

     const enviarPostback = async (idClick, idEvent) => {
          const url = 'https://conversions.kypi.io/postback?click_id=' + idClick + '&security_token=113432ed-a639-4bd8-8c46-fac84b818b91&event_id=' + idEvent;
          try {
               await fetch(url, { method: 'HEAD' });
          } catch (error) {
               // gravar log de erro
          }
     };

     const handleMailSenha = async (e) => {
          e.preventDefault();

          if (!email) {
               setAvisoSenha("Digite o Email")
               setShowAviso(true)
               return
          } else {
               setAvisoSenha("")
               setShowAviso(false)
          }

          // Controla a qualidade da senha
          const senhaRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[@$!%*#?&]).{8,}$/;
          if (!senhaRegex.test(senha)) {
               if (min8Color == 'gray') {
                    setMin8Color('red')
               }
               if (maiusculaColor == 'gray') {
                    setMaiusculaColor('red')
               }
               if (minusculaColor == 'gray') {
                    setMinusculaColor('red')
               }
               if (specialColor == 'gray') {
                    setSpecialColor('red')
               }
               if (numberColor == 'gray') {
                    setNumberColor('red')
               }
               // setar classe do input de senha
               setSenhaclass("entrada-invalida");
               return;
          } else {
               setSenhaclass("");
               setVerifysenha("")
          }

          // Compara senhas digitadas
          if (senha !== confSenha) {
               setAvisoSenha(t('supwrng5'));
               setShowAviso(true)
               return
          }

          await createUserWithEmailAndPassword(auth, email, senha)
               .then((userCredential) => {

                    const user = userCredential.user;
                    setUsuario(userCredential.user);
                    setSignupres("")

                    // Envia email de confirmação
                    sendMailVerify(user);

                    // Grava dados na tabela
                    const uid = user.uid
                    const provider = user.providerId
                    const verificado = user.emailVerified
                    const user_status = 0

                    createUser(uid, email, provider, user_status, idPais);

                    // redireciona
                    if (verificado) {
                         navigate("/")
                    } else {
                         navigate("/verifyemail")
                    }

               })
               .catch((error) => {
                    // gravar erros em log nosql
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(errorCode, errorMessage)
                    if (errorCode === 'auth/email-already-in-use') {
                         setSignupres(t('supwrng1'))
                         setMailClass("entrada-invalida")
                    } else {
                         setSignupres(t('supwrng2'), errorCode)
                         setMailClass(null);
                    }
               });
     }

     async function createUser(uid, email, provider, user_status, id_country) {
          try {
               const response = await fetch("https://www.superbuxx.com:3030/user-create", {
                    method: "POST",
                    headers: {
                         "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ uid, email, provider, user_status, id_country }),
               });
               // aqui verifica se a api gravou os dados
               const data = await response.json();
               if (data.sucesso === true) {
                    // [JUL-2024] - grava evento em ads_tracking
                    if (adsTrackClickId) {
                         addNewAdEvent(adsTrackClickId, uid, "signup")
                         enviarPostback(adsTrackClickId, "signup")
                    }

                    // [OUT - 2024] - RODA TAG GOOGLE
                    gtag('event', 'ads_conversion_S_inscrire_1', {
                         // <event_parameters>
                    });
               } else if (data.existe === "sim") {
                    console.log("Uid já existe!")
                    // não faz nada
               } else {
                    console.error("Erro ao criar o registro:", data.error);
                    // Se não criou registro
                    // 1 - grava log do erro
                    // 2 - seta mensagem, seta NAVIGATION
               }

          } catch (error) {
               // não acessou a api
               console.error("Erro ao acessar a api de gravação de dados:", error);
          }
     }

     async function sendMailVerify(dados) {
          // ### AQUI: implementar função que cria log em caso de erro de envio de email de verificação
          try {
               await sendEmailVerification(dados);
               setMailres(t('supwrng3'))

          } catch (error) {
               setMailres(t('supwrng3') + " " + error)
          }
     }

     return (

          <div className="full-screen-container">
               <div className="form-container">
                    <h2>{t('signuptitle')}</h2>
                    <form>
                         <div className="input-with-icon mb-3">
                              <img src="../src/assets/icons/icon-email-cadastro.svg" alt="Email Icon" />
                              <input
                                   type="email"
                                   name='email'
                                   placeholder={t('email')}
                                   onChange={(e) => setEmail(e.target.value)}
                                   onBlur={(e) => { setMailClass(""); setSignupres("") }}
                                   onFocus={(e) => { setMailClass(""); setSignupres("") }}
                              />
                         </div>
                         <div className="input-with-icon mb-3">
                              <img src="../src/assets/icons/icon-senha-cadastro.svg" alt="Email Icon" />
                              <input
                                   type="password"
                                   name='senha'
                                   placeholder={t('password')}
                                   onChange={(e) => setSenha(e.target.value)}
                                   onFocus={(e) => { setSenhaclass(""); setVerifysenha("") }}
                              />
                         </div>

                         <div className="input-with-icon mb-1">
                              <img src="../src/assets/icons/icon-senha-cadastro.svg" alt="Email Icon" />
                              <input
                                   type="password"
                                   placeholder={t('confirmpsw')}
                                   onChange={(e) => setConfSenha(e.target.value)}
                                   onBlur={(e) => setConfClass("")}
                                   onFocus={(e) => setAvisoSenha(false)}
                                   className={confClass}
                              />
                         </div>
                         {avisoSenha ? (
                              <div className="error-container">
                                   <div className="error-icon"><img src="../src/assets/icons/icon-error-login.svg" alt="" /></div>
                                   <div className="error-text">{avisoSenha}</div>
                              </div>
                         ) : (null)}
                    </form>
                    <div className="list-confirmation-register mt-1">
                         <ul>
                              <li style={{ color: min8Color }}>{t('sutip1')}</li>
                         </ul>
                         <ul>
                              <li style={{ color: maiusculaColor }}>{t('sutip2')}</li>
                         </ul>
                         <ul>
                              <li style={{ color: minusculaColor }}>{t('sutip3')}</li>
                         </ul>
                         <ul>
                              <li style={{ color: specialColor }}>{t('sutip4')}</li>
                         </ul>
                         <ul>
                              <li style={{ color: numberColor }}>{t('sutip5')}</li>
                         </ul>
                    </div>

                    <div className="checkbox-container">
                         <input
                              type="checkbox"
                              id="accept"
                              className="custom-checkbox"
                              name="agree"
                              style={{ cursor: "pointer", margin: "0" }}
                              value={isAgreeCheck}
                              onChange={handleAgree}

                         />
                         <label htmlFor="accept">{t('sutip6')}</label>
                    </div>
                    <div className="btn-cadastro">
                         <button
                              type="button"
                              className="btn-criar-conta mb-3"
                              onClick={handleMailSenha}
                              disabled={stateBtCriar}
                         >
                              {t('criarcontabt')}
                         </button>
                         {signupres ? (
                              <div className="error-container">
                                   <div className="error-icon"><img src="../src/assets/icons/icon-error-login.svg" alt="" /></div>
                                   <div className="error-text">{signupres}</div>
                              </div>
                         ) : (null)}

                         <p className="mb-3"><a href="/">{t('tocancelbt')}</a></p>
                         <p>{t('dohaveaccnt')}</p>
                         <p><a href="/login">{t('connect')}</a></p>
                    </div>
               </div>
          </div>
     )
}

export default SignUpMail