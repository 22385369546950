import "./offerwalls.css"
import React, { Fragment, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { recLastActv } from "../../components/functions/RecLastActv";
import { useEffect } from "react";
import SeoTags from "../common/SeoTags";

const LootablyOfferWall = props => {
     const { usuario } = useContext(AuthContext)
     const { uid } = usuario
     const url = `https://wall.lootably.com/?placementID=cm1jgo0ji00hh01xlcjm7g3fx&sid=${uid}`
     const video = `https://api.lootably.com/api/offerwall/redirect/offer/101-999?placementID=cm1jgo0ji00hh01xlcjm7g3fx&rawPublisherUserID=${uid}`

     useEffect(() => {
          if (uid) {
               recLastActv(uid, "Entrou ow Torox")
          }
     }, [uid])

     return (
          <Fragment>
               <SeoTags
                    title="Lootably Offerwall"
                    description="Lootably Offerwall"
                    url="https://www.superbuxx.com/lootably"
               />
               <div className="mt-5">
                    <div className="offerwall">
                         <iframe
                              title = "Lootably Offer Wall"
                              allow= "clipboard-write"
                              src={url}
                              style={{
                                   height: "100vh",
                                   width: "100%",
                                   margin: 0,
                                   padding: 0,
                                   border: 0,
                                   frameborder: 0
                              }}
                         >
                         </iframe>
                    </div>
               </div>
               {/* <div className="mb-3">
                    <a href={video} target="_blank">Video</a>
               </div> */}
          </Fragment>
     )
}
export default LootablyOfferWall