import { getYear4Y } from '../../helpers/converters';
import { useTranslation } from 'react-i18next';

export default props => {
     const ano = getYear4Y("America/Sao_Paulo");
     const { t } = useTranslation()

     return (
          <footer className="footer text-white">
               <div className="container-fluid text-center">
                    <div className="row">
                         <div className="col-12">
                              <img
                                   className="mix-blend img-fluid spacing-footer-logo"
                                   src="image/logos/logo-nav.png"
                                   alt="Super Buxx Logo"
                                   width="112px"
                                   height="50"
                              />
                         </div>
                         <div className="col-12">
                              <ul className="list-inline links">
                                   <li className="list-inline-item"><a href="terms" className="text-white">{t('footertermos')}</a></li>
                                   <li className="list-inline-item"><a href="aboutus" className="text-white">{t('footeraboutus')}</a></li>
                                   <li className="list-inline-item"><a href="privacy-policy" className="text-white">{t('footerpolitics')}</a></li>
                                   <li className="list-inline-item"><a href="faq" className="text-white">{t('faqtitle')}</a></li>
                                   <li className="list-inline-item"><a href="mailto:contact@superbuxx.com" className="text-white">{t('footerhelp')}</a></li>
                              </ul>
                         </div>
                    </div>
                    <hr className="full-width-hr" />
                    <div className="row">
                         <div className="col-12">
                              <p>
                                   {t('footeraccept1')} <br />
                                   {t('footeraccept2')}
                              </p>
                              {/* <ul className="list-inline social-buttons">
                                   <li className="list-inline-item">
                                        <a href="#" className="social-button">
                                             <div className="circle-social-icons"></div>
                                             <img src="../src/assets/icons/facebook-icon.svg" alt="Facebook" className="social-icon" />
                                        </a>
                                   </li>
                                   <li className="list-inline-item">
                                        <a href="#" className="social-button">
                                             <div className="circle-social-icons"></div>
                                             <img src="../src/assets/icons/icon-x.svg" alt="X" className="social-icon" />
                                        </a>
                                   </li>
                                   <li className="list-inline-item">
                                        <a href="#" className="social-button">
                                             <div className="circle-social-icons"></div>
                                             <img src="../src/assets/icons/instagram.svg" alt="Instagram" className="social-icon" />
                                        </a>
                                   </li>
                                   <li className="list-inline-item">
                                        <a href="#" className="social-button">
                                             <div className="circle-social-icons"></div>
                                             <img src="../src/assets/icons/linkedin.svg" alt="LinkedIn" className="social-icon" />
                                        </a>
                                   </li>
                              </ul> */}
                              <hr className="full-width-hr" />
                         </div>
                    </div>
               </div>
               <div className="copy">
                    <p>{t('copyright', {
                         year: ano,
                    })}</p>
               </div>
          </footer>
     )
}
